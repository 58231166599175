var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`table ${_vm.className}`},[_c('div',{ref:"table",staticClass:"table__inner",on:{"mousedown":function($event){_vm.isDown = true;
      _vm.mousedown($event);},"mouseleave":function($event){_vm.isDown = false},"mouseup":function($event){_vm.isDown = false},"mousemove":function($event){return _vm.mousemove($event)}}},[_c('div',{staticClass:"table__content"},[_c('header',{staticClass:"table__header"},_vm._l((_vm.headers),function(item,key){return _c('div',{key:`header-${key}`,staticClass:"table__header_col",class:item.class},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(item.title))]),(item.sortable)?_c('span',{staticClass:"icons"},[_c('span',{staticClass:"icon",class:{
                'icon--active':
                  _vm.tableData && _vm.tableData.sort === item.key && _vm.tableData.order === 'asc',
              },on:{"click":function($event){item.sortable ? _vm.$emit('sortTable', { order: 'asc', sort: item.key }) : ''}}},[_c('IconArrowTop')],1),_c('span',{staticClass:"icon icon--down",class:{
                'icon--active':
                  _vm.tableData && _vm.tableData.sort === item.key && _vm.tableData.order === 'desc',
              },on:{"click":function($event){item.sortable ? _vm.$emit('sortTable', { order: 'desc', sort: item.key }) : ''}}},[_c('IconArrowTop')],1)]):_vm._e()])}),0),_c('div',{staticClass:"table__progress"},[_c('vue-progress-bar',{attrs:{"thickness":_vm.thickness}})],1),_c('div',{staticClass:"table__body",attrs:{"id":"infinite-scroll"},on:{"scroll":function($event){return _vm.scrollTable('#infinite-scroll')}}},[(_vm.items?.length)?_vm._l((_vm.items),function(item,key){return _c('div',{key:`row-${key}`,staticClass:"table__body_row"},_vm._l((_vm.columnNames),function(el,index){return _c('div',{key:`header-${index}`,staticClass:"table__body_col",class:el.class},[(!el.variables)?[_c('p',{staticClass:"table__item"},[_vm._v(" "+_vm._s(item[el.key])+" ")])]:[(el.variables === 'image')?_c('img',{attrs:{"src":item[el.key]}}):(el.variables === 'icon')?_c('span',{staticClass:"table__icon",class:`table__icon--${el.variables} ${el.variablesType} ${
                    el.key === 'view' && (item.status === 'inactive' || item.status === 'draft')
                      ? 'table__icon--disabled'
                      : ''
                  }`,on:{"click":function($event){return _vm.$emit('clickAction', {
                      key: el.key,
                      id: item.id,
                      item: item,
                    })}}},[_c(el.icon,{tag:"component"})],1):_vm._e(),(el.variables === 'tag')?_c('div',{staticClass:"table__item",class:`table__item--${el.variables}`},[_c('div',{class:{ tag: item[el.key].main }},[_c('p',[_vm._v(_vm._s(item[el.key].main || "—"))])]),(item[el.key].additional?.length)?_c('AdditionalInfo',{attrs:{"items":item[el.key].additional,"parentClientRect":_vm.tableClientRect},on:{"getParentClientRect":_vm.getParentClientRect}}):_vm._e()],1):_vm._e(),(el.variables === 'status')?_c('p',{staticClass:"table__item",class:`table__item--${el.variables}`},[_c('span',{staticClass:"table__item_circle",class:`table__item_circle--${item[el.key]}`}),_vm._v(" "+_vm._s(item[el.key])+" ")]):_vm._e(),(el.variables === 'double')?_c('p',{staticClass:"table__item table__item--double"},_vm._l((item[el.key]),function(one,idx){return _c('span',{key:idx},[_vm._v(" "+_vm._s(one)+" ")])}),0):_vm._e(),(el.variables === 'checkbox')?_c('div',{staticClass:"table__item",class:`table__item--${el.variables}`},[_c('BaseCheckbox',{staticClass:"checkbox--black",attrs:{"label":el.title,"checked":item[el.key]},on:{"input":function($event){return _vm.$emit('clickAction', {
                        key: el.key,
                        id: item.id,
                      })}}})],1):_vm._e()]],2)}),0)}):[_c('p',{staticClass:"table__body_empty"},[_vm._v("NO DATA")])]],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }